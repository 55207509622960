<template>
    <div class="page">
        <!-- 协议页 -->
        <div class="scroll_content">
            <div class='text_content'>
                <div>1 本条款的主体是您与广东一站网络科技有限公司（以下简称“一站网”）。<text
                        class='fz26 f333 fb'>请您认真阅读本条款，您使用本服务表明您已充分阅读、理解并接受本条款的全部内容，并确认您因工作需要使用本服务时，您已充分获得您工作单位的授权。</text>
                </div>
                <div>2 本条款履行过程中如发生争议，双方应友好协商解决。如协商不成的，应向“一站网”所在地有管辖权的人民法院提起诉讼。</div>
                <div>3 您同意遵守本公司的隐私政策及不时修订的内容，同意“一站网”按照法律法规和隐私政策的规定处理您提供的相关信息。</div>
                <div>4 仓储服务定义</div>
                <div>4.1 储管理服务：““一站网””根据您的需求为您提供仓储管理及相关服务，具体服务包含入库验收、商品存储、订单生产、其他出入库、退仓处理以及增值服务等。
                </div>
                <div>4.2 仓储服务订单：您通过“一站网”指定的管理平台向“一站网”下达仓储管理服务需求的信息。</div>
                <div>4.3 仓储管理服务费：本条款及项下所有附件及补充协议所包含的费用统称。</div>
                <div>4.4 商品：是指由您交付并委托“一站网”根据本条款及项下补充协议、附件进行的仓储管理服务的物品。</div>
                <div>4.5
                    商品实际价值：是指商品本身的成本价格或损失发生时商品或同类商品成本价值，不包括基于商品可能获得的收益、利润、实际用途、市场机会等任何直接、间接损失，或特殊商业价值等。</div>
                <div>4.6
                    库存周转天数的计算方式=（自然月每天库存全部商品件数数量总和）/（自然月每天销售出库商品件数数量总和）；库存商品件数的统计包含其他出入库（逆向）上架的商品，销售出库商品件数以“一站网”系统中记录的您商品订单生产状态为复核环节（含）后的商品件数为准；自然月每天库存全部商品件数数量总和、自然月每天销售出库商品件数数量总和的范围：所适用的含库存周转天数条款的报价所对应的您商品，以“一站网”确认为准。
                    义务与权利</div>
                <div>5.1
                    您有义务保证，其向“一站网”交付的您商品中不包含：发票、有价证券、国家相关法律法规禁寄的刊物、文物、护照、私人证件、单证、协议、批文、现金、私人信函、核销单、动物、药物、毒品、仿制物品、产地来源不明物品、存在腐蚀性或放射性危险、易燃易爆品、白色粉末等一切物品；
                </div>
                <div>5.2 您有义务确保提交“一站网”进行仓储管理服务的您商品及其外包装满足以下条件： </div>
                <div>5.2.1 符合国家相关法律、法规的规定，不属于禁止生产、销售、传播的物品；</div>
                <div>5.2.2 符合国家相关法律、法规规定的或者与第三方约定的关于质量、安全、环保等标准；</div>
                <div>5.2.3不会对“一站网”以及任何第三方造成包括但不限于人身、财产等损害；</div>
                <div>5.2.4
                    在“一站网”向您提供仓储服务管理的过程中，您商品不会被政府部门或司法机关查封、扣押，或者被第三人追索或主张任何权利，也不会侵犯他人的专利权、商标权、著作权等；</div>
                <div>5.3
                    您同意并理解，在“一站网”提供仓储管理服务的过程中，存在某些非“一站网”可控制的挤压、冲撞等影响安全的因素；您同意根据商品的实际状况，积极采取妥善包装等手段，使相应风险得到最大程度的降低；
                </div>
                <div>5.4
                    对于因特殊物流属性需提供特殊仓储管理服务的商品，例如易碎易损、异味、隐私商品等，或需要采取特殊保管措施的高值商品，例如珠宝、金条等，您须如实在您商品入库前的信息维护操作中向“一站网”进行明示，否则“一站网”将视其为普通商品提供仓储管理服务，由此带来的所有损失由您承担；
                </div>
                <div>5.5 您有义务按照本条款中有关仓储服务费计费规则、计算方式及结算支付等约定向“一站网”支付仓储服务费；</div>
                <div>5.6
                    您应严格遵照本合同及附件中关于商品入库服务（含仓间调拨入库）、存储服务、出库生产服务、销退服务、退仓服务（含仓间调拨出库）以及增值服务中对具体操作流程、操作方式、细节的规定； </div>
                <div>5.7
                    若因您商品自身包装问题导致商品在仓储管理服务过程中造成的毁损、灭失等，“一站网”将不承担任何责任，若因此造成“一站网”损失的，您须承担全部赔偿责任。</div>
                <div>5.8
                    合作期间您如需使用“一站网”商标，需严格按照“一站网”许可并提供的商标图样或在商标局申请注册的商标图样、“一站网”规定（详见“一站网”官方网站服务细则）使用本商标。未经“一站网”书面同意，您不得对商标图样及对应使用的具体服务项目做任何修改，包括但不限于简化、简称、改变字体、图样、颜色等，否则视为对“一站网”商标的侵权。
                </div>
                <div>5.9
                    您应妥善保管登录“一站网”系统的账户名称及账户密码，所有通过您的账户名称及密码登录系统进行操作的行为均视为您或您授权的行为，并对相应行为承担全部责任。您已充分知悉账户名称及密码的重要性及因保管不善导致账户名和密码被泄露而造成的所有后果，并承担所有责任。
                </div>
                <div>6 “一站网”义务与权利</div>
                <div>6.1
                    您通过“一站网”指定的管理平台向“一站网”下达仓储管理服务需求并经“一站网”接受后，若您因特殊情况需取消或变更需求，应及时通知“一站网”，对于“一站网”在收到通知前已提供仓储服务的部分，“一站网”有权按相应的服务项目向您进行收费，需求状态以“一站网”系统记录为准；
                </div>
                <div>6.2
                    您将拟入库商品送达您指定的“一站网”仓库后，“一站网”有权根据业务需要，现场排定入库收货作业的顺序并有权按照“一站网”相关商品入库标准进行验收（注：“一站网”仅就拟入库您商品进行外观抽查验收，对于商品的实际价值、质量等以及销售包装内是否含实物不负有审核的能力与义务），对于验收不符合标准的您商品“一站网”有权拒收并退还您，由您负责自行提取拒收商品；
                </div>
                <div>6.3 “一站网”有权对您提供的您商品销售外包装信息内容进行审查，对于您商品外包装出现有损“一站网”的负面信息的，“一站网”有权拒收处理；</div>
                <div>6.4
                    “一站网”有权按照相关法律、法规的规定、司法、行政机关的要求，或在“一站网”认为必要且经您确认后，对商品进行开封、检查、核实。若发现商品不属于本合同服务范围的，“一站网”有权拒收、退回或按照相应国家机关要求处理，对于“一站网”已提供仓储服务部分，“一站网”有权按相应的服务项目向您收取费用；
                </div>
                <div>6.5
                    若您未按照本合同约定向“一站网”支付仓储服务费及其他费用的，“一站网”有权直接从您及其关联公司与“一站网”及“一站网”关联（包含协议控制）公司签署的任何协议的待结算款项中直接扣除；对不足以抵扣的，“一站网”有权就商品进行留置（留置期限为两个月，自欠费发生之日起计算），若留置期限届满您仍未付清相关费用的，“一站网”有权将商品进行变卖并优先受偿；若变卖所得价款仍不足以抵偿相关费用(包括但不限于未支付的仓储服务费用、实现留置权的费用等)的，“一站网”仍有权要求您继续支付；
                </div>
                <div>6.6
                    “一站网”有权将本条款中部分或全部权利、义务转移给“一站网”关联（包含协议控制）公司，亦有权将本合同及项下中服务项目全部或部分授权、转包给“一站网”指定的第三方执行，但会向您事先披露或通知。同时，“一站网”应确保“一站网”关联（包含协议控制）公司和/或“一站网”指定的第三方提供服务之质量。
                </div>
                <div>7 违约责任</div>
                <div>7.1
                    若您未按照本条款约定的支付时间以及本条款项下附件约定的计价规则向“一站网”支付仓储服务费用，每逾一日（自欠费发生之日起计算）您须按其所欠缴仓储服务费用的5‰向“一站网”支付违约金。若逾期支付达30日及以上，“一站网”有权直接终止本合同并要求您立即办理退仓等相关手续，由此所产生的双方所有损失由您承担；
                </div>
                <div>7.2
                    您因违反本合同规定的义务导致“一站网”遭受政府部门、机场、港口管理部门等机构采取强制措施、行政处罚，或遭受第三人索赔的，您应赔偿“一站网”所遭受的所有损失，赔偿范围包括但不限于损害赔偿金、行政机关的处罚及相应的诉讼、律师、鉴定、差旅等费用；
                </div>
                <div>7.3 您违反本合同及其附件就相关具体操作、细节的规定给“一站网”带来的所有损失均由您承担。</div>
                <div>8 商品的毁损、灭失赔偿</div>
                <div>8.1 甲乙双方就商品的毁损、灭失赔偿应根据本合同及项下附件、补充协议中所规定的具体内容执行；</div>
                <div>8.2 因下列原因造成“一站网”无法按本合同规定执行仓储管理服务的，“一站网”不承担赔偿责任：</div>
                <div>8.2.1
                    因不可抗力因素造成的；“不可抗力”是指无法预测、无法控制或无法避免的客观因素或意外事件，包括但不限于地震、火灾、雪灾、暴风雨、大雾等恶劣天气；罢工、恐怖事件、意外交通事故、法规政策的修改、政府、司法机关的行为、决定或命令、发生重大疫情政府进行交通管制；抢劫、飞车抢夺等暴力犯罪；
                </div>
                <div>8.2.2 因商品的自然性质、内在或潜在缺陷或自然磨损、自然损耗造成的；</div>
                <div>8.2.3 您过错造成的。</div>
                <div>8.3
                    若您或第三方就您商品购买了保险，若因发生保险事故导致您商品毁损、灭失的，保险公司已经向您或第三方承担或许诺承担保险理赔责任后，“一站网”在保险公司理赔范围内不再承担赔偿责任。对于非“一站网”原因造成的货物毁损、灭失的，保险公司拒赔或部分拒赔时的拒赔部分“一站网”不负责赔偿。
                </div>
                <div>9 保密与反商业贿赂条款</div>
                <div>9.1
                    您及您工作人员，保证对从“一站网”取得且无法自公开渠道获得的商业秘密（包括但不限于产品价格、技术信息、经营信息）予以保密。未经“一站网”或商业秘密提供者的书面许可，您不得向任何第三方泄露该商业秘密的全部或部分内容。否则应向“一站网”赔偿由于商业秘密泄露所造成的经济损失。保密期限直至该保密信息由提供者公开为止。
                </div>
                <div>9.2
                    您及您工作人员在合作期间，未经“一站网”另行书面授权，不得自行或允许他人对外披露与“一站网”的合作事宜，不得使用“一站网”包含保密信息在内的任何专利申请权或专利权、商标权、著作权等知识产权，或该知识产权的使用权。
                </div>
                <div>9.3
                    您同意遵守“一站网”官方网站所公示的隐私政策及其不时修订的内容，并且同意“一站网”及关联公司按照法律规定和隐私政策收集、保管、使用、存储、披露及传输您提供的物流信息。</div>
                <div>9.4 双方公司及工作人员在合作期间， 应依法办事、廉洁自律， 应避免与对方发生利益冲突，
                    且不得自行或通过第三方为谋求交易机会或竞争优势及其他合作的利益， 向对方公司及员工进行商业贿赂，
                    包括但不限于提供一切物质及精神上的直接或间接不正当利益。 其中不正当利益包括物质性利益和非物质性利益。</div>
                <div>9.5
                    您违反以上保密及廉洁义务，经调查属实，“一站网”有权终止合同履行，且可以要求您消除影响、赔礼道歉、赔偿“一站网”因此造成的全部损失及费用支出。并可将您列入不合格合作伙伴名单，不再进行合作。
                </div>
                <div>10 通知与送达</div>
                <div>10.1
                    合同履行过程中，一方传递给另一方的通知；或在诉讼程序中，法院对一方或双方进行书面通知的，按照本合同约定或您在“一站网”系统中填写的地址进行送达。书面通知的形式包括但不限于专人送达、挂号信、快递等形式，及电子邮件、手机短信、传真、系统通知等电子方式，在采用电子方式进行书面通知的情况下发送即视为送达。
                </div>
                <div>10.2
                    该联系方式下进行的本合同有关的行为视为您授权行为。如一方联系人、联系地址、电话、传真和电子邮箱任何一项发生变更，有义务及时通知另一方。如一方的通讯地址有误或者未按照本条的规定履行变更通知义务或接收通知的一方拒收，则通知或函件被退回之日视为送达日。
                </div>
                <div>11 其他约定</div>
                <div>11.1
                    为实现合同目的或“一站网”经营需要，您确认在本合同有效期内，“一站网”提前五日通知（通过邮件或书面或通知函或公告等形式）您，“一站网”有权将本合同项下的全部或部分权利义务转让给第三方，由该方替换“一站网”作为合同主体就原合同继续享有全部权利和履行全部义务。
                </div>
                <div>11.2
                    在合作过程中，任何一方若对本合同及其相关规则的具体内容有异议的，双方应积极协商签署补充协议。若双方无法达成一致的，则您应立即停止使用“一站网”所提供的任何服务，同时须与“一站网”完成合同期内所产生的所有仓储管理服务费结算后终止本合同，否则视为您默认对本合同内容无异议。
                </div>
                <div>11.3 本合同中的纠纷，由双方协商解决；若协商不一致，双方同意将争议提交合同签订地人民法院诉讼解决。 </div>
                <div>11.4
                    本合同项下的附件及补充协议与本合同具有同等法律效力，附件及补充协议中未涉及的事项均按照本合同约定执行；“一站网”以书面方式通知您的操作手册、指南等相关文件作为本合同的附件，您应予以遵守。</div>
                <div>12 仓储管理服务通用计价规则</div>
                <div>12.1 赠品视同普通商品，计费分类标准与普通商品规则保持一致。</div>
                <div>12.2
                    您需“一站网”提供仓储管理服务的商品，应保证商品相关信息的准确性，其中长、宽、高、重量信息必须准确无误，若出现因商品长、宽、高、重量信息不一致而产生的仓储服务费用差异，“一站网”有权向您追讨差异费用。
                </div>
                <div>12.3 您对于商品名称字段对商品实物进行清晰准确描述，“一站网”将以商品名称、商品物流属性进行服务费用类别的归属。</div>
                <div>12.4
                    您同意按照“一站网”官方网站上公布的标准价格向“一站网”支付服务费用，且认可“一站网”有权根据经营需要对官网价格进行调整，并按照最新价格进行结算。双方另有约定的除外。</div>
                <div>12.5
                    春节、十一等法定节假日和“618”、“双十一”等购物节业务高峰期间需要提供服务的，“一站网”可能会加收附加服务费，具体收费标准以“一站网”官方网站公布为准。</div>
                <div>12.6
                    由于不可抗力等情况但需要继续提供服务的，“一站网”有权对不可抗力发生期间的服务收费标准进行调整，包括双方另行以补充协议等方式约定的各类收费标准及折扣标准，具体调整方案以“一站网”官方网站公布为准。
                </div>
                <div>12.7 按照“一站网”促销政策，若您满足“一站网”的相关促销政策，您享受“一站网”促销价格调整可不签署价格协议而直接适用“一站网”公告的内容。
                </div>
                <div>13 赔偿标准及结算支付</div>
                <div>13.1 赔偿标准</div>
                <div>13.1.1 由于“一站网”过错，造成您商品毁损灭失、错发、漏发等，超出本合同约定的免责标准范围外的金额由“一站网”承担赔偿责任；</div>
                <div>13.1.2
                    因“一站网”过错造成毁损、灭失的您单一售卖商品，“一站网”将按照您商品的成本价作为赔付标准，您须提供您商品采购协议或发票等成本价相关证明材料，以甲乙双方确认为准，赔付商品在“一站网”确认赔付后物权归“一站网”所有；
                </div>
                <div>13.1.3
                    若因“一站网”过错造成本合同约定的仓储管理服务未能如约履行，您须提供直接经济损失的书面证明文件，“一站网”按照证明文件判断是否需“一站网”赔偿及所需赔偿的金额，双方确认后由“一站网”赔偿您因未能履行义务带来的直接损失；
                </div>
                <div>13.1.4 因您商品自身包装或质量等原因， 造成的“一站网”库房及人员等的安全危险及损失， 您承担一切责任。</div>
                <div>13.2 免责标准</div>
                <div>13.2.1
                    “一站网”在为您提供仓储管理服务中，由于过错带来您商品的毁损、灭失，您接受的您商品容错标准为：自然月库存商品总件数的差异率（商品总件数盘亏）≤0.03%的误差损失，您同意“一站网”免责，差异率内的您商品范围以盘点时间顺序进行统计，且不计入库存商品成本金额盘亏的统计；
                </div>
                <div>13.2.2
                    “一站网”有权将库存商品成本金额盘盈SKU的商品与库存商品成本金额盘亏SKU商品的商品赔付标准进行相互抵消，若抵消后仍为库存商品成本总金额盘亏，则“一站网”进行相应赔偿，若抵消后为库存商品成本总金额盘盈，则“一站网”有权在之后的盘点时予以商品成本金额盘亏抵消，以上库存商品成本金额盘盈亏时包含在库破损的您商品的统计。
                </div>
                <div>14
                    一站网会以电话、电子邮件、手机短信等方式告知、发送的产品/业务介绍、推广促销信息及其他商业性信息。您不同意继续接收以上信息的，可以通过短信或拨打一站网客服热线方式退订。</div>
                <div>15 上述条款未涉及事宜，按照国家相关法律法规及标准执行。</div>
                <div>16
                    您确认，您已充分阅读、理解并接受本条款的全部内容。即便本条款中的某条款被法院或政府机构认定为无效或者部分无效的，您也同意除无效条款外仍继续执行其他条款。</div>
                <div>17
                    为反映有关法律法规的变化以及一站网服务内容的变动，一站网可能会不时修订或更新本服务条款，并将修订或更新版本内容在本页面发布，请点击本服务条款时仔细阅读，服务条款及不时修订或更新版本内容自发布之日生效。
                </div>
            </div>
            <div style="height:100px"></div>
        </div>
        <div class='foot'>
            <div class='btn-agreenment' @click='agreenAction'>
                <van-button round>同意</van-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        mounted() { },
        methods: {
            agreenAction(e) {
                let parmsJson = localStorage.getItem('parmsJson')
                let parms = JSON.parse(parmsJson)
                parms.isChecked = true
                localStorage.setItem('parmsJson', JSON.stringify(parms))
                this.$router.go(-1);//返回上一层
            }
        }

    }

</script>
<style scoped>
    /* pages/bill/agreement/index.wxss */
    .page {
        background-color: #f5f5f5;
    }

    .foot {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 180px;
        background-color: white;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
        text-align: center;
    }

    .btn-agreenment {
        display: inline-block;
        margin: 0 auto;
        width: 80%;
        font-size: 36px;
        line-height: 90px;
    }

    .btn-agreenment button {
        width: 100%;
        background: linear-gradient(#007ec5, #007EC5);
        color: #fff;
    }

    .scroll_content {
        padding: 0px 30px 0px 30px;
        text-align: center;
    }

    .text_title {
        width: 690px;
        font-size: 30px;
        font-stretch: normal;
        font-weight: bold;
        line-height: 29px;
        letter-spacing: 0px;
        color: #333333;
        margin-top: 30px;
        font-weight: 500;
    }

    .text_content {
        display: inline-block;
        max-width: 1000px;
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2em;
        letter-spacing: 0px;
        /* color: #666666; */
        color: #333333;
        margin-top: 20px;
        text-align: left;
    }

    .text_content div {
        margin-top: 10px;
    }

    .text_content_fb {
        width: 690px;
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 35px;
        letter-spacing: 0px;
        /* color: #666666; */
        color: #333333;
        margin-top: 20px;
    }

    .under_line {
        border-bottom: 1px solid #333;
    }

    @media screen and (min-width:1000px) {
        .btn-agreenment {
            max-width: 200px;
        }
    }
</style>